import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from './breadcrumb';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbMessage = new BehaviorSubject<Breadcrumb>({} as Breadcrumb);
  breadcrumbMessage$ = this.breadcrumbMessage.asObservable();

  setBreadcrumbMessage(breadcrumbUpdates: Breadcrumb | Breadcrumb[] ) {
    if(Array.isArray(breadcrumbUpdates)){
      breadcrumbUpdates.forEach((breadcrumbUpdate) => {
        this.breadcrumbMessage.next(breadcrumbUpdate);
      });
    }else{
      this.breadcrumbMessage.next(breadcrumbUpdates);
    }
  }
}

import { Observable } from "rxjs";
import { ITurbineRepository } from "src/app/domain/repositories/turbine-repository.interface";
import { ITurbineService } from "./turbine.service.interface";
import { Injectable } from "@angular/core";
import { TurbineDetail } from "src/app/domain/models/turbine-detail";
import { ReportResponse } from "src/app/domain/models/report-response";

@Injectable({
    providedIn: 'root'
  })

export class TurbineService implements ITurbineService {
    
    constructor(
        private turbineRepository: ITurbineRepository
    ){ }


    getAll(farmId: string): Observable<TurbineDetail[]> {
        return this.turbineRepository.getAll(farmId);
    }
    getTurbineDetail(farmId: string, turbineId: string): Observable<TurbineDetail> {
        return this.turbineRepository.getTurbineDetail(farmId,turbineId);
    }
    generateTurbineReport(farmId: string, turbineId: string): Observable<ReportResponse> {
        return this.turbineRepository.generateTurbineReportURL(farmId, turbineId);
    }
}